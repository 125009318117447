import {View} from 'react-native';

import Text from '@/components/basic/text';
import theme from '@/style';
import DiceItem from './dice-item';
import React from 'react';
import {useResponsiveDimensions} from '@/utils';
import {useTranslation} from 'react-i18next';

const BetsTip = ({
  type = 'any',
}: {
  type: 'single' | 'double' | 'triple' | 'any';
}) => {
  const {width} = useResponsiveDimensions();
  const {i18n} = useTranslation();

  // const tip = React.useMemo(() => {
  //   switch (type) {
  //     case 'single':
  //       return 'Choose 3 or more different numbers. If the draw results match your selection, you win.';
  //     case 'double':
  //       return 'Choose 2 same numbers. lf the drawresults match your selection, you win(unless 3 numbers are the same).';
  //     case 'triple':
  //       return 'Choose 3 same numbers. lf the drawresults match your selection, you win.';
  //     case 'any':
  //       return 'Choose any 3 same numbers. lf the drawresults are any three of the same numbers.you win.';
  //   }
  // }, [type]);

  return (
    <View
      style={[{width: 0.75 * width}, theme.padding.l, theme.borderRadius.l]}>
      {type === 'single' && (
        <View
          style={[
            theme.flex.row,
            theme.flex.center,
            {gap: theme.paddingSize.l},
          ]}>
          <DiceItem diceSize={48} diceNum={1} />
          <DiceItem diceSize={48} diceNum={2} />
          <DiceItem diceSize={48} diceNum={3} />
        </View>
      )}
      {type === 'double' && (
        <View
          style={[
            theme.flex.row,
            theme.flex.center,
            {gap: theme.paddingSize.s},
          ]}>
          <DiceItem diceSize={56} diceNum={4} />
          <DiceItem diceSize={56} diceNum={4} />
        </View>
      )}
      {type === 'triple' && (
        <View style={[theme.flex.centerByCol]}>
          <DiceItem diceSize={48} diceNum={6} />
          <View
            style={[
              theme.flex.row,
              theme.flex.center,
              {gap: theme.paddingSize.s},
            ]}>
            <DiceItem diceSize={48} diceNum={6} />
            <DiceItem diceSize={48} diceNum={6} />
          </View>
        </View>
      )}
      {type === 'any' && (
        <View style={[theme.flex.centerByCol]}>
          <DiceItem diceSize={48} diceNum={'any'} />
          <View
            style={[
              theme.flex.row,
              theme.flex.center,
              {gap: theme.paddingSize.s},
            ]}>
            <DiceItem diceSize={48} diceNum={'any'} />
            <DiceItem diceSize={48} diceNum={'any'} />
          </View>
        </View>
      )}
      <Text
        style={[theme.margin.tops]}
        size="medium"
        textAlign="center"
        color={'var(--T2)'}>
        {i18n.t(`game-page.tip.${type}`)}
      </Text>
    </View>
  );
};

export default BetsTip;
