import React from 'react';
import {COLOR_MAP} from '../../constant';
import {DiceResultItem} from '../../dice-service';

const AnalyzeNum = ({list = []}: {list?: DiceResultItem[]}) => {
  return (
    <div className="grid grid-cols-13 gap-px">
      {list.map((item, _i) => (
        <AnalyzeNumItem
          bigOrSmall={item.bigOrSmall}
          result={item.result}
          totalCount={item.totalCount}
          key={_i}
        />
      ))}
    </div>
  );
  // return (
  //   <View
  //     style={[
  //       theme.flex.row,
  //       theme.flex.wrap,
  //       // eslint-disable-next-line react-native/no-inline-styles
  //       {
  //         gap: 2,
  //       },
  //     ]}>
  //     {list.map((item, _i) => (
  //       <AnalyzeNumItem
  //         bigOrSmall={item.bigOrSmall}
  //         result={item.result}
  //         totalCount={item.totalCount}
  //         key={_i}
  //       />
  //     ))}
  //   </View>
  // );
};

interface AnalyzeNumItemProps {
  totalCount?: number;
  result?: string;
  bigOrSmall: number;
}

const AnalyzeNumItem = ({
  totalCount = 0,
  bigOrSmall = 1,
  result = '',
}: AnalyzeNumItemProps) => {
  const color = React.useMemo(() => {
    return COLOR_MAP[bigOrSmall];
  }, [bigOrSmall]);

  return (
    <div className="flex flex-col py-1 gap-1 items-center bg-image rounded">
      <span className="text-[.625rem]" style={{color: color}}>
        {totalCount}
      </span>
      <span className="text-[.625rem] text-t2">
        {result ? result.split(',').join('') : '-'}
      </span>
    </div>
  );
};

export default AnalyzeNum;
