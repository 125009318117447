import {View} from 'react-native';
import DiceGameBetsType from './select-tab';
import React from 'react';
import SumPoints from './tab-content/sum';
import Single from './tab-content/single';
import DoubleDice from './tab-content/double';
import TripleDice from './tab-content/triple';
import MyBets from '../my-bets/my-bets';
import {DiceConfigInfo} from '../dice-service';

interface betsInfoType {
  type: string;
  code: string;
  digit: number;
  digitType: string;
}

export interface BetsSelectProps {
  onSelect?: (code: string, value: string | number) => void;
  disabled?: boolean;
  diceConf?: DiceConfigInfo;
  betsList?: betsInfoType[];
  onTip?: (type: 'single' | 'double' | 'triple' | 'any') => void;
}

const BetsSelect = ({
  diceConf = {
    ruleLinkUrl: '',
    videoLinkUrl: '',
    cycle: 0,
    id: 0,
    minRemainingSecond: 0,
  },
  onSelect = () => {},
  onTip = () => {},
  disabled = false,
  betsList = [],
}: BetsSelectProps) => {
  const [active, setActive] = React.useState(0);

  return (
    <View>
      <DiceGameBetsType onTabChange={setActive} activeIndex={active} />
      <View
        style={
          // eslint-disable-next-line react-native/no-inline-styles
          {backgroundColor: 'var(--BG)'}
        }>
        {active === 0 && (
          <SumPoints
            disabled={disabled}
            diceConf={diceConf}
            onSelect={onSelect}
          />
        )}
        {active === 1 && (
          <TripleDice
            disabled={disabled}
            onTip={onTip}
            diceConf={diceConf}
            onSelect={onSelect}
          />
        )}
        {active === 2 && (
          <DoubleDice
            disabled={disabled}
            onTip={() => onTip('double')}
            diceConf={diceConf}
            onSelect={onSelect}
          />
        )}
        {active === 3 && (
          <Single
            disabled={disabled}
            onTip={() => onTip('single')}
            diceConf={diceConf}
            onSelect={onSelect}
          />
        )}
        <MyBets betsList={betsList} />
      </View>
    </View>
  );
};

export default React.memo(BetsSelect);
