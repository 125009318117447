import LinearGradient from '@/components/basic/linear-gradient';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import Question from './question';

export interface BetsTabHeaderProps {
  tip?: string;
  onTip?: () => void;
}

const BetsTabHeader = (props: BetsTabHeaderProps) => {
  const {tip, onTip} = props;

  return (
    <LinearGradient
      colors={['rgba(255, 255, 255, 0.20)', 'rgba(255, 255, 255, 0)']}
      style={[styles.container, theme.padding.lrl, theme.padding.tbm]}>
      {tip && (
        <View style={[theme.flex.row]}>
          <Text size="medium" style={[theme.margin.rights]}>
            {tip}
          </Text>
          <Question onPress={onTip} />
        </View>
      )}
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderTopColor: 'var(--game-line)',
  },
});

export default BetsTabHeader;
