import {View, StyleSheet, Image} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import {DICES} from '../../constant';
import BigSmall from './analyze-big-small';
import {StatisticsResultItem, getStatisticsResult} from '../../dice-service';
import {SafeAny} from '@/types';

const DICES_NUMBER = [
  [
    {
      dice: 1,
      value: 'oneCount',
    },
    {
      dice: 2,
      value: 'twoCount',
    },
  ],
  [
    {
      dice: 3,
      value: 'threeCount',
    },
    {
      dice: 4,
      value: 'fourCount',
    },
  ],
  [
    {
      dice: 5,
      value: 'fiveCount',
    },
    {
      dice: 6,
      value: 'sixCount',
    },
  ],
] as {dice: number; value: string}[][];

const ROUND_TAB = [50, 100, 200, 600];

const AnalyzeRound = ({configId}: {configId?: number}) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [info, setInfo] = React.useState<StatisticsResultItem | SafeAny>();

  const getInfo = async (id: number, count: number) => {
    try {
      const res = await getStatisticsResult(count, id);
      if (res) {
        setInfo(res);
      }
    } finally {
    }
  };

  React.useEffect(() => {
    if (configId) {
      getInfo(configId, ROUND_TAB[activeIndex]);
    }
  }, [activeIndex, configId]);

  return (
    <>
      <View style={[theme.background.white, theme.padding.topl]}>
        <View
          style={[
            // eslint-disable-next-line react-native/no-inline-styles
            {
              borderBottomColor: 'var(--card)',
              borderBottomWidth: 1,
            },
            theme.padding.lrl,
          ]}>
          <View
            style={[
              theme.flex.row,
              theme.flex.alignStart,
              // eslint-disable-next-line react-native/no-inline-styles
              {marginBottom: -1.5, columnGap: 4},
            ]}>
            {ROUND_TAB.map((item, index) => (
              <RoundTabItem
                key={index}
                active={index === activeIndex}
                label={item}
                onChangeTab={() => setActiveIndex(index)}
              />
            ))}
          </View>
        </View>
        <View
          style={[
            {gap: theme.paddingSize.m * 2},
            theme.padding.lrl,
            theme.margin.tbl,
          ]}>
          {DICES_NUMBER.map((item, _i) => {
            return (
              <View
                style={[theme.flex.row, {gap: theme.paddingSize.m * 2}]}
                key={_i}>
                {item.map((pie, _j) => (
                  <RoundContentItem
                    key={_j}
                    dice={pie.dice}
                    percent={info ? info[pie.value] : 0}
                  />
                ))}
              </View>
            );
          })}
        </View>
      </View>
      <BigSmall small={info?.smallCount} big={info?.bigCount} />
    </>
  );
};

interface RoundTabItemProps {
  active?: boolean;
  label?: number;
  onChangeTab?: () => void;
}
const RoundTabItem = ({
  active = false,
  label = 50,
  onChangeTab = () => {},
}: RoundTabItemProps) => {
  return (
    <NativeTouchableOpacity
      onPress={onChangeTab}
      style={[
        theme.flex.flex1,
        theme.flex.center,
        theme.borderRadius.xs,
        active ? styles.active : styles.normal,
      ]}>
      <Text main={active}>{label} Round</Text>
    </NativeTouchableOpacity>
  );
};

const RoundContentItem = ({
  dice = 1,
  percent = 0,
}: {
  dice: number;
  percent?: number;
}) => {
  return (
    <View style={[theme.flex.row, theme.flex.centerByCol, theme.flex.flex1]}>
      <Image source={DICES[dice]} style={styles.dice} />
      <View style={[theme.flex.flex1, theme.flex.between]}>
        <Text second fontFamily="fontInterBold" textAlign="center">
          {percent}%
        </Text>
        <View style={[styles.bar]}>
          <View style={[styles.barInner, {width: `${percent}%`}]} />
        </View>
      </View>
    </View>
  );
};

export default React.memo(AnalyzeRound);

const styles = StyleSheet.create({
  active: {
    height: 34,
    backgroundColor: 'var(--card)',
    borderTopColor: theme.basicColor.primary,
    borderTopWidth: 2,
  },
  normal: {
    height: 30,
    backgroundColor: 'var(--image)',
  },
  dice: {
    width: 28,
    height: 28,
    marginRight: 8,
  },
  bar: {
    backgroundColor: '#CED2D6',
    height: 8,
    borderRadius: 8,
    overflow: 'hidden',
  },
  barInner: {
    backgroundColor: '#F15802',
    height: '100%',
  },
});
