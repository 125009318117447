import LinearGradient from '@/components/basic/linear-gradient';
import {TimeRemaining} from '@/components/business/games';
import theme from '@/style';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import Svg, {Line} from 'react-native-svg';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import DicePrevResult from './component/dice-prev-result';
import {useTranslation} from 'react-i18next';

export interface DiceTimeResultProps {
  issueNumber?: string;
  cycle?: number;
  prevIssueNum?: string;
  countdownTime?: number;
  showRule?: () => void;
  bigOrSmall?: number;
  oddOrEven?: number;
  // 结果是按照逗号隔开的，目前暂定1、4是红色的，其他都是黑色的，后面再说
  result?: string;
  totalCount?: number;
  onTimeEnd?: () => void;
  onDisabled?: () => void;
  isSameRoute?: boolean;
  stopBetSec?: number;
  timeVersion?: number;
  onTimeChange?: (v: number) => void;
}

const DiceTimeResult = ({
  issueNumber,
  cycle = 0,
  prevIssueNum,
  countdownTime = -1,
  result,
  bigOrSmall,
  totalCount,
  oddOrEven,
  showRule = () => {},
  stopBetSec,
  onTimeEnd,
  onDisabled,
  isSameRoute = true,
  timeVersion = 0,
  onTimeChange,
}: DiceTimeResultProps) => {
  const {i18n} = useTranslation();
  return (
    <LinearGradient colors={theme.backgroundColor.remainingLinearGradient}>
      <View
        style={[
          theme.flex.row,
          theme.padding.lrl,
          theme.padding.tbxxl,
          theme.flex.centerByCol,
        ]}>
        <View>
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.flex.between,
            ]}>
            <View>
              <Text
                color={'var(--T2-dark)'}
                size="medium"
                fontFamily="fontInterBold">
                {cycle || '-'}min
              </Text>
              <Text color={'var(--T2-dark)'} fontFamily="fontInterBold">
                {prevIssueNum || '-'}
              </Text>
            </View>
            <NativeTouchableOpacity
              onPress={() => showRule && showRule()}
              style={[
                theme.borderRadius.xs,
                styles.plyBtn,
                theme.flex.center,
                theme.padding.tbxxs,
                theme.padding.lrs,
                theme.margin.tops,
                {
                  height: 28,
                },
              ]}>
              <Text color={'var(--T2-dark)'} fontFamily="fontInter">
                {i18n.t('game-page.label.howToPlay')}
              </Text>
            </NativeTouchableOpacity>
          </View>
          <View>
            <DicePrevResult
              bigOrSmall={bigOrSmall}
              total={totalCount}
              oddOrEven={oddOrEven}
              result={result ? result.split(',') : []}
            />
          </View>
        </View>
        <View style={[theme.flex.flex1, theme.flex.centerByCol]}>
          <Svg height={72} width={1}>
            <Line
              x1="0"
              y1="0"
              x2="0"
              y2="72"
              stroke={theme.backgroundColor.colorDashed}
              strokeWidth="2"
              strokeDasharray={'5,5'}
            />
          </Svg>
        </View>
        <TimeRemaining
          remainingTime={countdownTime}
          tip={issueNumber}
          stopBetSec={stopBetSec}
          onDisabled={onDisabled}
          onTimeEnd={onTimeEnd}
          isSame={isSameRoute}
          timeVersion={timeVersion}
          onTimeChange={onTimeChange}
        />
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  plyBtn: {
    backgroundColor: theme.backgroundColor.ruleBtn,
  },
});

export default DiceTimeResult;
