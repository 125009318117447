/* eslint-disable react-native/no-inline-styles */
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import {TypeListItem} from '../../dice-service';
import theme from '@/style';
import OrderBetsListItem from './order-bets-list-item';
import MoreBtn from '@/components/business/order/more-btn';
import {useTranslation} from 'react-i18next';

const whenNumToHide = 5;

const OrderBetsList = ({
  typeList = [],
  status = 2,
  betsTime,
}: {
  typeList: TypeListItem[];
  status: 0 | 1 | 2;
  betsTime?: string;
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const {i18n} = useTranslation();
  const list = React.useMemo(() => {
    if (typeList.length <= whenNumToHide) {
      return typeList;
    } else {
      return showMore ? typeList : typeList.slice(0, whenNumToHide);
    }
  }, [showMore, typeList]);

  return (
    <View style={[theme.margin.lrl]}>
      <View style={styles.header}>
        <View style={[{width: 52}]}>
          <Text>{i18n.t('bets-detail.label.number')}</Text>
        </View>
        <View style={[{width: 72}]}>
          <Text textAlign="center">{i18n.t('bets-detail.label.gameType')}</Text>
        </View>
        <View style={[theme.flex.flex1, theme.flex.center]}>
          <Text>{i18n.t('bets-detail.label.payment')}</Text>
        </View>
        <View style={[theme.flex.flex1, theme.flex.alignEnd]}>
          <Text>{i18n.t('bets-detail.label.result')}</Text>
        </View>
      </View>
      <View>
        {list.map((item, _i) => (
          <OrderBetsListItem
            betsTime={betsTime}
            key={_i}
            item={item}
            index={_i}
            status={status}
          />
        ))}
      </View>
      {typeList.length > whenNumToHide && !showMore && (
        <MoreBtn onPress={() => setShowMore(true)} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'var(--image)',
    paddingHorizontal: 12,
    paddingVertical: 8,
    columnGap: 8,
  },
});

export default OrderBetsList;
