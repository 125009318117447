import theme from '@/style';
import React from 'react';
import {View, Image, StyleSheet} from 'react-native';
import {DICES, SINGLE} from '../../constant';
import DiceContainer from '../../component/dice-container';
import DiceItem from '../../component/dice-item';
import Text from '@/components/basic/text';
import Question from '../../component/question';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import BetsTabHeader from '../../component/bets-tab-header';
import {DiceConfigInfo} from '../../dice-service';
import {useTranslation} from 'react-i18next';
import {BasicObject} from '@/types';

const GAP = 8;
const prefix = 'leopard_';

const TripleDice = ({
  diceConf = {
    ruleLinkUrl: '',
    videoLinkUrl: '',
    cycle: 0,
    id: 0,
    minRemainingSecond: 0,
    optionOdds: undefined,
  },
  onSelect = () => {},
  onTip = () => {},
  disabled = false,
}: {
  diceConf?: DiceConfigInfo;
  onSelect?: (code: string, value: string | number) => void;
  disabled?: boolean;
  onTip?: (type: 'single' | 'double' | 'triple' | 'any') => void;
}) => {
  const {i18n} = useTranslation();
  return (
    <>
      <BetsTabHeader
        tip={i18n.t('game-page.tip.triple-odd')}
        onTip={() => onTip('triple')}
      />
      <View
        style={[
          theme.padding.lrl,
          theme.flex.row,
          theme.flex.wrap,
          {gap: GAP},
        ]}>
        {SINGLE.map((item, _i) => (
          <DiceContainer
            disabled={disabled}
            itemNumber={3}
            key={_i}
            onPress={() => onSelect(`${prefix}${item}`, _i + 1)}>
            <View style={[theme.flex.centerByCol]}>
              <DiceItem disabled={disabled} diceSize={28} diceNum={_i + 1} />
              <View style={[theme.flex.row, theme.flex.centerByCol]}>
                <DiceItem disabled={disabled} diceSize={28} diceNum={_i + 1} />
                <DiceItem disabled={disabled} diceSize={28} diceNum={_i + 1} />
              </View>
            </View>
            <Text style={[theme.margin.topxxs]}>
              {diceConf.optionOdds
                ? diceConf.optionOdds[`${prefix}${item}`]
                : '0'}
              X
            </Text>
          </DiceContainer>
        ))}
      </View>
      <View style={[theme.flex.row, theme.flex.centerByCol, theme.padding.m]}>
        <Text size="medium" style={[theme.margin.rights]}>
          {i18n.t('game-page.tip.any-odd')}
        </Text>
        <Question onPress={() => onTip('any')} />
      </View>
      <NativeTouchableOpacity
        disabled={disabled}
        onPress={() => onSelect(`${prefix}any`, 'any')}
        style={[
          theme.flex.row,
          theme.flex.center,
          {
            backgroundColor: 'var(--image)',
            borderWidth: 1,
            borderColor: 'rgba(255, 255, 255, 0.1)',
          },
          {boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'} as BasicObject,

          theme.padding.tbl,
          theme.margin.lrl,
          theme.borderRadius.m,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            elevation: 5,
          },
        ]}>
        <Image source={DICES.any} style={styles.diceAny} />
        <Image source={DICES.any} style={styles.diceAny} />
        <Image source={DICES.any} style={styles.diceAny} />
        <Text style={[theme.margin.lefts]}>
          {diceConf.optionOdds ? diceConf.optionOdds[`${prefix}any`] : '0'}X
        </Text>
      </NativeTouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  diceAny: {
    width: 28,
    height: 28,
  },
});

export default TripleDice;
