import {SafeAny} from '@/types';
import {http, indusWinHttp} from '@/utils';

export interface IUserInfo {
  canWithdrawAmount: number; // 可兑现金额
  createTime: number;
  level: number;
  rechargeAmount: number; // 充值金额
  userBalance: number; // 用户总额
  userPhone: string;
  userId: number;
  updateTime: number;
  lastLoginTime: number;
  // 缺少用户名
  [k: string]: SafeAny;
}

export interface SlotegratorStartParams {
  id: string;
  forFun: string;
}

export interface AllCasinoRedirectInfo {
  gameName: string;
  gameUrl: string;
}

/** 获取用户信息 */
export function postUserInfo() {
  return http.post<null, IUserInfo>('app/user/info');
}

export function getSlotegratorGameStart(gameId: string) {
  return indusWinHttp.get<SlotegratorStartParams, string>(
    '/iGaming/casino/getGamesStart',
    {
      params: {
        id: gameId,
        forFun: 'false',
      },
    },
  );
}

export function postLiveAuthorize() {
  return indusWinHttp.post<null, {authorize: string}>('iGaming/liveAuthorize');
}

export function getKMAuthorize(tripartiteUniqueness: string) {
  return indusWinHttp.get<
    {tripartiteUniqueness: string},
    {active: boolean; authToken: string}
  >('iGaming/km/authorize', {
    params: {
      tripartiteUniqueness,
    },
  });
}

export function getWSStart(id: string) {
  return indusWinHttp.get<{id: string}, string>('/iGaming/ws/getGamesStart', {
    params: {id},
  });
}

export function getEvolution(gameId: string) {
  return indusWinHttp.get<{gameId: string}, string>(
    '/iGaming/evolution/getGameStart',
    {
      params: {gameId},
    },
  );
}

export function getCD(gameId: string) {
  return indusWinHttp.get<{gameId: string}, string>(
    '/iGaming/cd/game/authorize',
    {
      params: {gameId},
    },
  );
}

export function getAllCasinoRedirect(id: number) {
  return indusWinHttp.get<{id: number}, AllCasinoRedirectInfo>(
    '/iGaming/common/getGameStart',
    {
      params: {
        id,
      },
    },
  );
}

export interface IGameMode {
  demo: boolean;
  gameName: string;
  gameUrl: string;
}
export const getGameMode = (id: string) => {
  return http.get<null, IGameMode>(`iGaming/common/getGameDemo?id=${id}`);
};
