/* eslint-disable react-native/no-inline-styles */
import {View} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import Ball from '../../component/ball';
import {DiceResultItem} from '../../dice-service';
import DiceItem from '../../component/dice-item';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

const ResultItem = (
  props: DiceResultItem & {
    index?: number;
    isLast?: boolean;
    countHasBg?: boolean;
  },
) => {
  const {
    issNo,
    totalCount,
    bigOrSmall,
    oddOrEven,
    result = '',
    index = 0,
    isLast,
    countHasBg,
  } = props;

  const backgroundColor = React.useMemo(() => {
    return {
      backgroundColor: index % 2 === 1 ? 'var(--image)' : '#31343D',
    };
  }, [index]);

  const resultArr = React.useMemo(() => {
    return result.split(',');
  }, [result]);

  const {calcActualSize} = useScreenSize();

  return (
    <View
      style={[
        theme.flex.row,
        {columnGap: theme.paddingSize.l},
        backgroundColor,
        theme.margin.lrl,
        theme.flex.centerByCol,
        theme.padding.l,
        isLast && {
          borderBottomLeftRadius: theme.borderRadiusSize.m,
          borderBottomRightRadius: theme.borderRadiusSize.m,
        },
      ]}>
      <View style={[theme.flex.row, theme.flex.flex1, theme.flex.centerByCol]}>
        <View style={[{width: calcActualSize(64)}]}>
          <Text calc>{issNo}</Text>
        </View>
        <View style={[theme.flex.row, theme.flex.flex1, theme.flex.center]}>
          {resultArr.map((item, _i) => (
            <DiceItem
              diceSize={calcActualSize(24)}
              diceNum={Number(item)}
              key={_i}
            />
          ))}
        </View>
      </View>

      <View style={[theme.flex.row, theme.flex.flex1, theme.flex.between]}>
        <View style={{width: calcActualSize(26)}}>
          {countHasBg ? (
            <View style={[theme.flex.center, {height: calcActualSize(24)}]}>
              <View style={[theme.position.abs]}>
                <DiceItem diceNum={'ball'} diceSize={calcActualSize(24)} />
              </View>
              <Text
                fontFamily="fontDinBold"
                calc
                textAlign="center"
                size="medium">
                {totalCount || '-'}
              </Text>
            </View>
          ) : (
            <Text calc textAlign="center" size="medium">
              {totalCount || '-'}
            </Text>
          )}
        </View>
        <View style={{flex: 1, alignItems: 'center'}}>
          {bigOrSmall === 0 ? (
            <Text calc size="medium">
              -
            </Text>
          ) : (
            <Ball
              isNum={false}
              ballSize={24}
              type={bigOrSmall === 1 ? 'big' : 'small'}
            />
          )}
        </View>
        <View style={{width: calcActualSize(48), alignItems: 'center'}}>
          {oddOrEven === 0 ? (
            <Text calc size="medium">
              -
            </Text>
          ) : (
            <Ball
              isNum={false}
              ballSize={calcActualSize(24)}
              type={oddOrEven === 1 ? 'odd' : 'even'}
            />
          )}
        </View>
      </View>
    </View>
  );
};

export default ResultItem;
