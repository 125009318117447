import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import {StyleSheet} from 'react-native';
import theme from '@/style';
import React from 'react';

const Question = ({onPress = () => {}}: {onPress?: () => void}) => {
  return (
    <NativeTouchableOpacity
      onPress={onPress}
      style={[styles.container, theme.flex.center]}>
      <Text size="medium" fontFamily="fontInterBold" color={'white'}>
        ?
      </Text>
    </NativeTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: '#FF7A00',
  },
});

export default Question;
