import theme from '@/style';
import React from 'react';
import {View} from 'react-native';
import BetsTabItem from '../component/bets-tab-item';
import {useTranslation} from 'react-i18next';

const BETS_TYPES = [
  {
    label: 'totalLabel',
    type: 'sum',
  },
  {
    label: 'triple',
    type: 'leopard',
  },
  {
    label: 'double',
    type: 'double',
  },
  {
    label: 'single',
    type: 'single',
  },
];

export interface DiceGameBetsTypeProps {
  onTabChange?: (i: number) => void;
  activeIndex?: number;
}

const DiceGameBetsType = ({
  onTabChange = () => {},
  activeIndex = 0,
}: DiceGameBetsTypeProps) => {
  const {i18n} = useTranslation();
  return (
    <View
      style={[
        theme.padding.lrl,
        theme.padding.topl,
        theme.flex.row,
        // eslint-disable-next-line react-native/no-inline-styles
        {gap: theme.paddingSize.xxs, backgroundColor: 'var(--BG)'},
      ]}>
      {BETS_TYPES.map((item, _i) => (
        <BetsTabItem
          onTabChange={() => onTabChange(_i)}
          key={_i}
          label={i18n.t(`game-page.label.${item.label}`)}
          active={_i === activeIndex}
        />
      ))}
    </View>
  );
};

export default React.memo(DiceGameBetsType);
